<template>
  <a-layout class="rays-tuner-help">
    <a-layout-sider id="help_sider_scroll" width="280" class="left-con">
      <a-menu
        mode="inline"
        style="width: 100%"
        :openKeys="openKeys"
        :selectedKeys="selectedKeys"
        @click="menuClick"
      >
        <template v-for="item in helpData" :key="item.key">
          <template v-if="item.children.length === 0">
            <a-menu-item :key="item.key">
              {{ item.title }}
            </a-menu-item>
          </template>
          <template v-else>
            <sub-menu :menu-info="item" :key="item.key" />
          </template>
        </template>
      </a-menu>
    </a-layout-sider>

    <a-layout-content
      id="help_content_scroll"
      :style="{ margin: 0, minHeight: '280px', padding: '40px' }"
    >
      <div v-for="(item, index) in helpImage" :key="index">
        <div v-if="showHelp === item.key">
          <a-image
            v-for="(img, index) in item.images"
            :key="index"
            :preview="false"
            :src="img"
          />
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import storage from "../../models/storage";
import { message } from "ant-design-vue";
import store from "@/vuex/store";
import PerfectScrollbar from "perfect-scrollbar";
import api from "@/api/pat-manager";
import { getBaseUrl } from "@/api/service";
const engineurl = getBaseUrl("engine");

const SubMenu = {
  name: "SubMenu",
  template: `
    <a-sub-menu :key="menuInfo.key">
      <template #title>{{ menuInfo.title }}</template>
      <div v-if="menuInfo.key===0">
        <a-menu-item :key="menuInfo.key">
          {{ menuInfo.title}}
        </a-menu-item>
      </div>
      <div v-else>
        <a-menu-item :key="menuInfo.key">
          {{ menuInfo.title.slice(2) }}
        </a-menu-item>
      </div>
      <template v-for="item in menuInfo.children" :key = "item.key">
        <template v-if="item.children.length === 0">
          <a-menu-item :key="item.key">
            {{ item.title }}
          </a-menu-item>
        </template>
        <template v-else>
          <sub-menu :menu-info="item" :key="item.key" />
        </template>
      </template>
    </a-sub-menu>
  `,
  props: {
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};

export default defineComponent({
  name: "Help",
  components: {
    "sub-menu": SubMenu,
  },
  setup() {
    const user_name = store.state.user.userinfo;
    let helpKey = storage.get("help_selected_key");
    if (!helpKey) {
      helpKey = {
        menukey: "2",
      };
      storage.set("help_selected_key", helpKey);
    }
    const selectedKeys = ref([helpKey.menukey]);
    const openKeys = ref(["2"]);
    const showHelp = ref('2');
    const helpData = ref([]);
    const helpImage = ref([]);
    const menuClick = ({ key }) => {
      // console.log("menuClick:", key);
      storage.set("help_selected_key", key);
      showHelp.value = key;
      document.getElementById('help_content_scroll').scrollTop = 0;
      // console.log("showHelp:",showHelp.value);
    };
    // const subClick = ({ key, domEvent }) => {
    //   console.log("subClick:", key, domEvent);
    // };

    const getHelpData = async () => {
      // console.log('id',user_name);
      const params = {
        user: user_name.email,
      };
      const res = await api.getWaterMarkerDocForUser(params, {
        noMessage: true,
      });
      if (!res || res.code != 0) {
        message.error("获取帮助中心数据失败!");
        return;
      }
      //console.log("getHelpData:", res);
      res.doc[Object.keys(res.doc)].forEach((item, index) => {
        if (typeof item === "string") {
          const obj = {
            key: index,
            title: String(Object.keys(res.doc)),
            images: [],
            children: [],
          };
          // let image = engineurl + "/api/sopt/simplifier/getWaterMarkerDocByFilename?filename=" + item + "&user=" + user_name.email;
          let image = engineurl + "/api/sopt/simplifier/getWaterMarkerDocByFilename?"  + "&user=" + user_name.email + "&filename=" + item;
          obj.images.push(image);
          helpData.value.unshift(obj);
        } else {
          helpData.value.push(cancelHelpData(item, index));
        }
      });
      showHelp.value = helpData.value[0].key;
      // console.log("helpData:", helpData.value);
      cancelHelpImage(helpData.value);
      // console.log("helpImage:", helpImage.value);
    };
    const cancelHelpData = (data, index) => {
      let mark = index;
      const menuObj = {
        key: index,
        title: String(Object.keys(data)),
        images: [],
        children: [],
      };
      data[Object.keys(data)].forEach((item, index) => {
        if (typeof item === "string") {
          let imgs = engineurl + "/api/sopt/simplifier/getWaterMarkerDocByFilename?" + "&user=" + user_name.email + "&filename=" + item;
          menuObj.images.push(imgs);
        } else {
          menuObj.children.push(cancelHelpData(item, mark + "." + index));
        }
      });
      return menuObj;
    };
    const cancelHelpImage = (data) => {
      data.forEach((item) => {
        const menuObj = {
          key: item.key,
          images: item.images,
        };
        helpImage.value.push(menuObj);
        if (item.children.length > 0) {
          cancelHelpImage(item.children);
        }
      });
    };
    getHelpData();
    let helpSiderDOM = null;
    let helpSiderPs = null;
    let helpConDOM = null;
    let helpConPs = null;
    onMounted(() => {
      helpSiderDOM = document.querySelector("#help_sider_scroll");
      helpSiderPs = new PerfectScrollbar(helpSiderDOM);
      helpConDOM = document.querySelector("#help_content_scroll");
      helpConPs = new PerfectScrollbar(helpConDOM);
    });
    onBeforeUnmount(() => {
      if (helpSiderPs) {
        helpSiderPs.destroy();
        helpSiderPs = null;
      }
      helpSiderDOM = null;
      if (helpConPs) {
        helpConPs.destroy();
        helpConPs = null;
      }
      helpConDOM = null;
    });

    return {
      selectedKeys,
      openKeys,
      showHelp,
      menuClick,
      // subClick,
      helpData,
      helpImage,
    };
  },
});
</script>

<style scoped>
.rays-tuner-help-home {
  width: 100%;
  height: 100%;
}
.left-con {
  border-right: 1px solid #d8d8d8;
  background-color: #f9f9f9;
}
.left-con .ant-menu {
  height: 100%;
  border-right: 0px;
  background-color: #f9f9f9;
}
.menu-split {
  width: 192px;
  height: 1px;
  margin-left: 8px;
  border: 1px solid #f0f0f0;
}
.help-image {
  width: 100%;
  height: 100%;
}
#help_content_scroll {
  overflow: hidden !important;
  position: relative;
}
</style>
